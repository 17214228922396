<template>
  <div>
    <Alert />
    <div class="titre-container">
      <h1 class="mt-0 fs-b fs-2500 color-pr">Déclarations</h1>
    </div>
    <div
      class="avisTaxe hide-element-desktop"
      :class="{ 'is-os-mobile': isMobileDevice }"
    >
      <!-- Visible pour la vue mobile-->
      <div v-if="urlPRO.length > 0">
        <DeclarationAction
          :actions="urlPRO"
          :rsBtnDetail="false"
          :rsDisplay="true"
        />
      </div>
      <!-- Cas un seul lien dispo -->
      <div
        v-if="
          urlDPAE &&
          urlDPAE.actionMenu &&
          urlDPAE.actionMenu.typeComposant === 'LIEN' &&
          urlDPAE.actionMenu.cible
        "
      >
        <a
          href="#"
          v-on:click="dpaeAction($event, urlDPAE.actionMenu)"
          class="px-2 mx-2 pointer fr-link rs-font pl-0 ml-0 no-box-shadow border-btm"
          id="dec_03"
          :title="urlDPAE.actionMenu.libelle"
        >
          {{ urlDPAE.actionMenu.libelle }}</a
        >
        <Info
          v-if="urlDPAE.actionMenu.isInfoBulle"
          cssClass="fr-fi-information-line info a-info"
          href="#"
          :data="urlDPAE.actionMenu.textInfoBulle"
        />
      </div>
      <!-- Cas plusieurs liens-->
      <div
        v-if="
          urlDPAE &&
          urlDPAE.actionMenu &&
          urlDPAE.actionMenu.typeComposant === 'MENU' &&
          urlDPAE.listAction &&
          urlDPAE.listAction.length > 1
        "
      >
        <div class="dropdown show">
          <a
            class="btn btn-secondary no-box-shadow dropdown-toggle fr-link rs-font border-btm"
            :class="{ 'is-os-mobile': isMobileDevice }"
            href="#"
            role="button"
            id="dropdownMenuLink"
            title="Déclaration préalable à l'embauche"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ><i class="ri-more-2-fill"></i>
            Déclaration préalable à l'embauche
          </a>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <div
              v-for="(dpae, index) in urlDPAE.listAction"
              :key="`dpae-${index}`"
            >
              <a
                href="#"
                v-on:click="dpaeAction($event, dpae)"
                class="dropdown-item pointer fr-link no-box-shadow rs-font"
                :id="`dpae-${index}`"
                :title="dpae.libelle"
                ><i class="ri-external-link-line"></i> {{ dpae.libelle }}</a
              >
            </div>
          </div>
        </div>
        <Info
          v-if="urlDPAE.actionMenu.isInfoBulle"
          cssClass="fr-fi-information-line info a-info"
          href="#"
          :data="urlDPAE.actionMenu.textInfoBulle"
        />
      </div>
    </div>
    <Tabs @switchtab="goTo" :tab="activeTab" />
    <div class="tab pb-3 mb-3 bg-white">
      <div
        v-if="!isMobileDevice"
        class="d-flex pt-4 justify-content-end fs-925 pb-3"
      >
        <div v-if="urlPRO.length > 0">
          <DeclarationAction
            :actions="urlPRO"
            v-on:detail="goToAvisTaxeFonciere"
            :tagChapter1="getChapter1()"
            :tagChapter2="'impots'"
            :tagChapter3="''"
            :tagButton="true"
          />
        </div>
        <div
          class="vl m-10"
          v-if="
            urlPRO.length > 0 &&
            urlDPAE &&
            urlDPAE.listAction &&
            urlDPAE.listAction.length > 0
          "
        />
        <div
          v-if="
            urlDPAE &&
            urlDPAE.actionMenu &&
            urlDPAE.actionMenu.typeComposant === 'LIEN' &&
            urlDPAE.actionMenu.cible
          "
        >
          <span class="ri-file-user-line fr-link pr-0"></span>
          <a
            href="#"
            v-on:click="dpaeAction($event, urlDPAE.actionMenu)"
            class="px-2 mx-2 pointer fr-link rs-font pl-0 ml-0 no-box-shadow"
            id="dec_03"
            :title="urlDPAE.actionMenu.libelle"
          >
            {{ urlDPAE.actionMenu.libelle }}</a
          >
          <Info
            v-if="urlDPAE.actionMenu.isInfoBulle"
            cssClass="fr-fi-information-line info a-info dpae"
            href="#"
            :data="urlDPAE.actionMenu.textInfoBulle"
          />
        </div>
        <div
          v-if="
            urlDPAE &&
            urlDPAE.actionMenu &&
            urlDPAE.actionMenu.typeComposant === 'MENU' &&
            urlDPAE.listAction &&
            urlDPAE.listAction.length > 1
          "
        >
          <div class="dropdown show">
            <a
              class="no-box-shadow dropdown-toggle fr-link fs-14 mt-1"
              href="#"
              role="button"
              id="dropdownMenuLink"
              title="Déclaration préalable à l'embauche"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ri-file-user-line"></i><i class="ri-more-2-fill"></i>
              Déclaration préalable à l'embauche
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <div
                v-for="(dpae, index) in urlDPAE.listAction"
                :key="`dpae-${index}`"
              >
                <a
                  href="#"
                  v-on:click="dpaeAction($event, dpae)"
                  class="dropdown-item pointer fr-link no-box-shadow"
                  :id="`dpae-${index}`"
                  :title="dpae.libelle"
                  ><i class="ri-external-link-line"></i> {{ dpae.libelle }}</a
                >
              </div>
            </div>
          </div>

          <Info
            v-if="urlDPAE.actionMenu.isInfoBulle"
            cssClass="fr-fi-information-line info a-info dpae"
            href="#"
            :data="urlDPAE.actionMenu.textInfoBulle"
          />
        </div>
      </div>

      <div v-else class="rs-pd">
        <div
          class="fr-grid-row avisTaxe hide-element"
          :class="{ 'is-os-mobile': isMobileDevice }"
        >
          <div v-if="urlPRO.length > 0">
            <DeclarationAction
              :actions="urlPRO"
              :rsBtnDetail="false"
              :rsDisplay="true"
            />
          </div>
          <!-- cas un seul lien -->
          <div
            v-if="
              urlDPAE &&
              urlDPAE.actionMenu &&
              urlDPAE.actionMenu.typeComposant === 'LIEN' &&
              urlDPAE.actionMenu.cible
            "
          >
            <a
              href="#"
              v-on:click="dpaeAction($event, urlDPAE.actionMenu)"
              class="px-2 mx-2 pointer fr-link rs-font pl-0 ml-0 no-box-shadow border-btm"
              id="dec_03"
              :title="urlDPAE.actionMenu.libelle"
            >
              {{ urlDPAE.actionMenu.libelle }}</a
            >
            <Info
              v-if="urlDPAE.actionMenu.isInfoBulle"
              cssClass="fr-fi-information-line info a-info dpae"
              href="#"
              :data="urlDPAE.actionMenu.textInfoBulle"
            />
          </div>
          <div
            v-if="
              urlDPAE &&
              urlDPAE.actionMenu &&
              urlDPAE.actionMenu.typeComposant === 'MENU' &&
              urlDPAE.listAction &&
              urlDPAE.listAction.length > 1
            "
          >
            <div class="dropdown show">
              <a
                class="btn btn-secondary no-box-shadow dropdown-toggle fr-link rs-font border-btm"
                :class="{ 'is-os-mobile': isMobileDevice }"
                href="#"
                role="button"
                id="dropdownMenuLink"
                title="Déclaration préalable à l'embauche"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><i class="ri-more-2-fill"></i>
                Déclaration préalable à l'embauche
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <div
                  v-for="(dpae, index) in urlDPAE.listAction"
                  :key="`dpae-${index}`"
                >
                  <a
                    href="#"
                    v-on:click="dpaeAction($event, dpae)"
                    class="dropdown-item pointer fr-link no-box-shadow rs-font"
                    :id="`dpae-${index}`"
                    :title="dpae.libelle"
                    ><i class="ri-external-link-line"></i> {{ dpae.libelle }}</a
                  >
                </div>
              </div>
            </div>
            <Info
              v-if="urlDPAE.actionMenu.isInfoBulle"
              cssClass="fr-fi-information-line info a-info dpae"
              href="#"
              :data="urlDPAE.actionMenu.textInfoBulle"
            />
          </div>
        </div>
      </div>

      <div v-if="checkHabilitationDeclarations && hasSourceDataDeclarations">
        <SearchFilter
          :items="filterItems"
          :displayFilterPlus="false"
          :paginationName="dataSourcePaginationName"
          :key="searchFilterKey"
        />
      </div>

      <table
        class="table table-borderless"
        :class="{ 'is-os-mobile': isMobileDevice }"
        v-if="checkHabilitationDeclarations && hasFilteredDataDeclarations"
      >
        <caption>
          Liste des déclarations
        </caption>
        <thead>
          <tr>
            <th scope="col" class="pr-3 w-30 fs-b">Taxe / Impôt</th>
            <th scope="col" class="pr-3 w-20 fs-b">Période de référence</th>
            <th scope="col" class="fs-b">Date limite de dépôt</th>
            <th scope="col" class="fs-b" v-if="activeTab === 'history'">
              Date du<br />
              dernier dépôt
            </th>
            <th scope="col" class="text-right last fs-b">
              <p class="mr-2 m-0">Actions</p>
            </th>
          </tr>
        </thead>
        <tbody ref="declarations">
          <template v-for="(item, indexCurrent) in paginatedDeclarations">
            <tr
              v-if="true"
              class="table-line mb-3 height-auto"
              :key="item.codeActivite"
              :id="`id-courant-${indexCurrent}`"
              :aria-rowindex="indexCurrent + 1"
            >
              <td
                class="white-space-default td-table-1-1td"
                :class="getClassRs(item)"
              >
                {{ item.redevabilite }}
                <p class="siret">
                  {{ item.precisionRedevabilite }}
                </p>
                <Info
                  cssClass="fr-fi-information-line info a-info"
                  v-if="item.contexte !== null"
                  href="#"
                  :data="iContext(item.contexte)"
                  :large="true"
                />
              </td>
              <td
                v-if="
                  (item.periodeReference &&
                    (item.periodeReference.dateDebut ||
                      item.periodeReference.dateFin)) ||
                  !isMobileDevice
                "
                class="td-table-1-1td"
                :class="getClassRs(item)"
              >
                {{
                  item.periodeReference &&
                  $filters.dateFormat(item.periodeReference.dateDebut)
                }}
                {{
                  item.periodeReference && item.periodeReference.dateDebut
                    ? '-'
                    : ''
                }}
                {{
                  item.periodeReference &&
                  $filters.dateFormat(item.periodeReference.dateFin)
                }}
              </td>
              <td
                v-if="item.dateLimiteDepot || !isMobileDevice"
                class="td-table-1-1td"
                :class="getClassRs(item)"
              >
                {{ $filters.dateFormat(item.dateLimiteDepot) }}
              </td>
              <td
                v-if="
                  activeTab === 'history' &&
                  (item.dateDernieDepot || !isMobileDevice)
                "
                class="td-table-1-1td"
                :class="getClassRs(item)"
              >
                {{ $filters.dateFormat(item.dateDernieDepot) }}
              </td>
              <td class="td-table-1-1td rs-p-t-0" :class="getClassRs(item)">
                <DeclarationAction
                  :actions="item.actions"
                  v-on:detail="showDetails(item, indexCurrent)"
                  :label="item.redevabilite"
                  :tagChapter1="getChapter1()"
                  :tagChapter2="getCopro(item)"
                  :tagChapter3="item.redevabilite"
                  :tagButton="true"
                  :data="item.contexte"
                  :detailsLiens="afficher(item)"
                />
              </td>
            </tr>
            <tr
              v-if="true"
              class="spacer rs-disappear"
              :key="`spacer-${indexCurrent}`"
              aria-hidden="true"
            ></tr>
          </template>
        </tbody>
      </table>

      <PaginationNG
        v-if="filteredDataDeclarations && filteredDataDeclarations.length"
        :name="dataSourcePaginationName"
        :datasource="filteredDataDeclarations"
        :perPage="perPage"
      />

      <div
        class="d-table no-data"
        v-if="checkHabilitationDeclarations && !hasFilteredDataDeclarations"
      >
        <p class="h3"><strong>Aucune déclaration connue</strong></p>
        <p>
          <img
            src="../assets/img/non-dispo.svg"
            alt="image d'un ordinateur avec sur l'écran une barre de chargement et un point d'exclamation"
          />
        </p>
      </div>
    </div>
    <NonHabilite
      v-if="!checkHabilitationDeclarations"
      classTexte="no-service col-md-8 col-12"
      classImage="col-md-4 col-12"
    />
    <div v-if="checkHabilitationRemboursements">
      <div class="mt-4">
        <div class="titre-container rs-titre-container">
          <h2 class="mt-0 fs-b fs-2500 color-pr rs-fs rs-line-h">
            Demandes de remboursement
          </h2>
        </div>
        <div class="d-table no-data" v-if="!hasSourceDataRemboursements">
          <p class="h3"><strong>Aucun remboursement connu</strong></p>
          <p>
            <img
              src="../assets/img/non-dispo.svg"
              alt="image d'un ordinateur avec sur l'écran une barre de chargement et un point d'exclamation"
            />
          </p>
        </div>
        <div v-else>
          <p class="rs-m">
            Seules les demandes de remboursement de TVA et de cotisations
            sociales sont disponibles dans le tableau ci-dessous.
          </p>
          <table
            class="table table-borderless"
            :class="{ 'is-os-mobile': isMobileDevice }"
          >
            <thead>
              <caption>
                Demandes de remboursement
              </caption>
              <tr>
                <th scope="col" class="pr-3">Taxe / Impôt</th>
                <th scope="col" class="pr-3">Période de référence</th>
                <th scope="col">
                  Date de réception <br />
                  de la demande
                </th>
                <th scope="col">
                  Statut de <br />
                  de la demande
                </th>
                <th scope="col" class="pr-3 text-right">Montant demandé</th>
                <th scope="col" class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody ref="remboursements">
              <tr
                class="table-line grey rs-table-2 height-auto mb-3"
                :key="remb.id"
                :id="`id-courant-${indexCurrent}`"
                v-for="(remb, indexCurrent) in paginatedRemboursements"
              >
                <td class="white-space-default td-table-2">
                  {{ remb.redevabilite }}
                  <Info
                    cssClass="fr-fi-information-line info a-info"
                    v-if="
                      remb.redevabilite.includes('TVA') &&
                      remb.contexte !== null
                    "
                    href="#"
                    :data="iContext(remb.contexte)"
                    :large="true"
                  />
                  <p class="siret">{{ remb.precisionRedevabilite }}</p>
                </td>
                <td class="td-table-2">
                  {{ $filters.dateFormat(remb.periodeReference.dateDebut) }} -
                  {{ $filters.dateFormat(remb.periodeReference.dateFin) }}
                </td>
                <td class="td-table-2">
                  {{ $filters.dateFormat(remb.dateReceptionDemande) }}
                </td>
                <td class="td-table-2">
                  {{ remb.statutLibelle }}
                </td>
                <td class="font-weight-bold td-table-2 rs-td text-right">
                  <span class="sr-only">
                    {{ $filters.numberSpacedRgaa(remb.montantDemande) }}</span
                  >
                  <span aria-hidden="true">
                    {{ $filters.numberSpaced(remb.montantDemande) }}
                  </span>
                </td>
                <td class="color-pr td-table-2 text-right">
                  <DeclarationAction
                    :actions="remb.actions"
                    v-on:detail="showDetailsRemb(remb, indexCurrent)"
                    :label="remb.redevabilite"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <PaginationNG
            v-if="dataSourceRemboursements && dataSourceRemboursements.length"
            :name="remboursementsPaginationName"
            :datasource="dataSourceRemboursements"
            :perPage="perPage"
          />
        </div>
      </div>
      <p class="text-center text-gray mt-5 pb-4">
        Ces informations sont données à titre indicatif et ne sont pas
        nécessairement exhaustives.
      </p>
    </div>
    <div v-else>
      <div class="mt-4">
        <div class="titre-container rs-titre-container">
          <span class="mt-0 fs-b fs-2500 color-pr rs-fs rs-line-h"
            >Demandes de remboursement</span
          >
        </div>
      </div>
      <NonHabilite
        classTexte="no-service col-md-8 col-12"
        classImage="col-md-4 col-12"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment';

import DeclarationAction from '../components/DeclarationAction';
import Tabs from '../components/TableTabs';
import { mapGetters, mapActions } from 'vuex';
import { dateFormat, numberSpaced } from '../filters';
import Info from '../shared/Info';
import SearchFilter from '../shared/SearchFilter';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import ZIndexMixin from '../mixins/z-index-mixin';
import Alert from '../shared/Alert';
import {
  iContext,
  sendTag,
  isMobileDevice,
  afficherDetail
} from '../utils/methodsUtils';
import NonHabilite from '../views/NonHabilite';
import {
  DETAILS_NAVIGATION,
  HEADER_ALERT,
  FILTER_FORM,
  FILTER_CLEAR_FORM,
  PAGINATION_RECORDS_PER_PAGE,
  PAGINATION_INIT_FOURCHETTE,
  PAGINATION_PAGINATE,
  SOURCE_PAGE_DECLARATION_DETAIL
} from '../constantes';
import store from '../store';

import PaginationNG from '../shared/PaginationNG.vue';

export default {
  components: {
    DeclarationAction,
    Tabs,
    Info,
    Alert,
    NonHabilite,
    SearchFilter,
    PaginationNG
  },

  mixins: [SkipLinkMixin, ZIndexMixin],

  data() {
    return {
      constDate: '',
      activeTab: 'current',
      searchFilterKey: 0,
      filterItems: [],
      filterValues: {},
      dataSourcePaginationName: 'tdb-declarations',
      paginatedDeclarations: [],
      remboursementsPaginationName: 'tdb-remboursement',
      paginatedRemboursements: [],
      perPage: PAGINATION_RECORDS_PER_PAGE,
      componentPath: this.$route.path,
      historyListCalled: false,
      window: {
        width: 0,
        height: 0
      },
      infoServiceDeclarations: false, //check si on a des erreurs dans la réponse des services de declaration en cours/historique
      infoServiceRemboursements: false //check si on a des erreurs dans la réponse des services de remboursement en cours/historique
    };
  },

  computed: {
    ...mapGetters('declaration', [
      'listCurrent',
      'listHistory',
      'urlDPAE',
      'urlPRO',
      'listAvisTF'
    ]),
    ...mapGetters('remboursement', [
      'currentRemboursement',
      'historyRemboursement'
    ]),
    ...mapGetters([
      'siren',
      'etablissements',
      'codeAffichages',
      'chargementCodeAffichages'
    ]),
    ...mapGetters(['getRouteData']),

    checkHabilitationDeclarations() {
      return this.codeAffichages['bloc-declarations'];
    },

    checkHabilitationRemboursements() {
      return this.codeAffichages['bloc-remboursements'];
    },

    dataSourceDeclarations() {
      return this.activeTab === 'current' ? this.listCurrent : this.listHistory;
    },

    hasSourceDataDeclarations() {
      return !!this.dataSourceDeclarations?.length;
    },

    filteredDataDeclarations() {
      const filters = this.filterValues;
      return Object.keys(filters).length
        ? this.dataSourceDeclarations.filter((it) =>
            this.recordMatches(it, filters)
          )
        : this.dataSourceDeclarations;
    },

    hasFilteredDataDeclarations() {
      return !!this.filteredDataDeclarations?.length;
    },

    dataSourceRemboursements() {
      return this.activeTab === 'current'
        ? this.currentRemboursement
        : this.historyRemboursement;
    },

    hasSourceDataRemboursements() {
      return !!this.dataSourceRemboursements?.length;
    },

    isMobileDevice() {
      return isMobileDevice();
    }
  },

  methods: {
    ...mapActions('declaration', [
      'fetchHistoryList',
      'fetchCurrentList',
      'fetchUrlDPAE',
      'fetchUrlPRO',
      'fetchListeAvisTF'
    ]),
    ...mapActions('remboursement', [
      'fetchHistoryRemboursement',
      'fetchCurrentRemboursement'
    ]),
    ...mapActions([
      'fetchCodeAffichages',
      'fetchIndicateurs',
      'setPreviousPath',
      'setRouteData'
    ]),

    recordMatches(record, filters) {
      if (
        filters.redevabilite?.length &&
        !filters.redevabilite.includes(record.redevabilite)
      ) {
        return false;
      }
      if (filters.periodeReference?.start && filters.periodeReference?.end) {
        const recordPeriodeReference = record.periodeReference;
        if (
          !recordPeriodeReference ||
          moment(recordPeriodeReference.dateDebut).isAfter(
            filters.periodeReference.end,
            'day'
          ) ||
          moment(recordPeriodeReference.dateFin).isBefore(
            filters.periodeReference.start,
            'day'
          )
        ) {
          return false;
        }
      }
      if (
        filters.dateLimiteDepot?.value &&
        (record.dateLimiteDepot === null ||
          !moment(record.dateLimiteDepot).isSame(
            filters.dateLimiteDepot.value,
            'day'
          ))
      ) {
        return false;
      }
      if (
        filters.dateDernierDepot?.start &&
        filters.dateDernierDepot?.end &&
        (record.dateDernieDepot === null ||
          !moment(record.dateDernieDepot).isBetween(
            filters.dateDernierDepot.start,
            filters.dateDernierDepot.end,
            null,
            '[]'
          ))
      ) {
        return false;
      }
      return true;
    },

    clearFilters() {
      this.resetFilters();
      this.resetPaginations();
    },

    resetFilters() {
      const items = [
        {
          name: 'redevabilite',
          label: 'Taxe/impôt',
          type: 'select-check',
          values: Array.from(
            new Set(
              this.dataSourceDeclarations
                .filter((i) => i.redevabilite)
                .map((i) => i.redevabilite)
            )
          ),
          hasInfo: false,
          messageInfo: '',
          value: '',
          filter: 1,
          ignoreFilterPlus: true,
          defaultValue: 'Tous',
          index: 0
        },
        {
          name: 'periodeReference',
          label: 'Période de référence',
          type: 'calendar',
          isRange: true,
          hasInfo: false,
          messageInfo: '',
          filter: 1,
          ignoreFilterPlus: true,
          index: 1,
          initValeur: ''
        },
        {
          name: 'dateLimiteDepot',
          label: 'Date limite',
          type: 'calendar',
          isRange: false,
          hasInfo: false,
          messageInfo: '',
          filter: 1,
          ignoreFilterPlus: true,
          index: 2,
          initValeur: ''
        }
      ];
      if (this.activeTab === 'history') {
        items.push({
          name: 'dateDernierDepot',
          label: 'Date de dernier dépôt',
          type: 'calendar',
          isRange: true,
          hasInfo: false,
          messageInfo: '',
          filter: 1,
          ignoreFilterPlus: true,
          index: 2,
          initValeur: ''
        });
      }
      this.filterItems = items;
      this.filterValues = {};
      ++this.searchFilterKey;
    },

    resetPaginations() {
      this.resetPaginationDeclarations();
      this.resetPaginationRemboursements();
    },

    resetPaginationDeclarations() {
      this.paginatedDeclarations = this.filteredDataDeclarations.slice(
        0,
        this.perPage
      );
      this.emitter.emit(
        `${PAGINATION_INIT_FOURCHETTE}.${this.dataSourcePaginationName}`
      );
    },

    resetPaginationRemboursements() {
      this.paginatedRemboursements = this.dataSourceRemboursements.slice(
        0,
        this.perPage
      );
      this.emitter.emit(
        `${PAGINATION_INIT_FOURCHETTE}.${this.remboursementsPaginationName}`
      );
    },

    afficher(item) {
      return afficherDetail(item, 'Declaration');
    },

    dgfipUrl() {
      return process.env.VUE_APP_URL_URSSAF_TI;
    },

    async goTo(nav) {
      this.removeZIndex();
      sendTag(
        nav === 'current' ? 'En_cours' : 'Historique',
        this.activeTab === 'current'
          ? 'declarations_en_cours'
          : 'declarations_historique',
        '',
        ''
      );
      this.activeTab = nav;
      if (!this.historyListCalled) {
        if (
          this.codeAffichages['bloc-declarations'] &&
          this.codeAffichages['bloc-remboursements']
        ) {
          await Promise.all([
            this.fetchHistoryList(this.$store.state.compte.siren),
            this.fetchHistoryRemboursement(this.$store.state.compte.siren)
          ]);
          this.gererErreurDecla('err-back-declarations-historique');
          this.gererErreurRemboursement(
            'err-back-declaration-remboursement-historique'
          );
        } else if (this.codeAffichages['bloc-declarations']) {
          await this.fetchHistoryList(this.$store.state.compte.siren);
          this.gererErreurDecla('err-back-declarations-historique');
        } else if (this.codeAffichages['bloc-remboursements']) {
          await this.fetchHistoryRemboursement(this.$store.state.compte.siren);
          this.gererErreurRemboursement(
            'err-back-declaration-remboursement-historique'
          );
        }
        this.historyListCalled = true;
      }
      this.clearFilters();
    },

    showDetails(el, index = 0) {
      // On enregistre les détails de la navigation de l'utilisateur pour que
      // quand il revient depuis la page de détails via le lien "retour au ..." ou le fil d'ariane
      // On le mènera vers la ligne en question
      localStorage.setItem(
        DETAILS_NAVIGATION,
        JSON.stringify({
          index: index,
          tab: this.activeTab,
          history: this.historyListCalled
        })
      );
      localStorage.setItem('detailDeclaration', JSON.stringify(el));
      const routeData = {
        source: SOURCE_PAGE_DECLARATION_DETAIL,
        item: el,
        tab: this.activeTab
      };
      this.setRouteDataAndPush(routeData, 'Détail déclaration');
    },

    showDetailsRemb(el, index = 0) {
      // On enregistre les détails de la navigation de l'utilisateur pour que
      // quand il revient depuis la page de détails via le lien "retour au ..." ou le fil d'ariane
      // On le mènera vers la ligne en question
      localStorage.setItem(
        DETAILS_NAVIGATION,
        JSON.stringify({
          index: index,
          tab: this.activeTab,
          table: 'remboursements',
          history: this.historyListCalled
        })
      );

      const routeData = {
        source: SOURCE_PAGE_DECLARATION_DETAIL,
        item: el,
        tab: this.activeTab
      };
      this.setRouteDataAndPush(routeData, 'Détail remboursement');
    },

    bordereauDate(array, period) {
      let date = '';
      let time = period === 0 ? 'dateDebut' : 'dateFin';
      array.forEach((el, index) => {
        date += dateFormat(el.periodePrincipale[time]);
        if (index < array.length - 1) date += '\n';
      });
      return date;
    },

    bordereauMontant(array, montant) {
      let date = '';
      let mnt =
        montant === 0
          ? 'montantDeclare'
          : montant === 1
          ? 'montantCalcule'
          : 'montantDeduction';
      array.forEach((el, index) => {
        date += numberSpaced(el[mnt]);
        if (index < array.length - 1) date += '\n';
      });
      return date;
    },

    iContext(context) {
      return iContext(context);
    },

    async goToAvisTaxeFonciere() {
      await this.fetchListeAvisTF(this.$store.state.compte.siren);
      const errFonct = store.state.erreur.details.filter(
        (d) =>
          d.id &&
          d.id.length &&
          d.id === 'err-back-liste-avis-taxe-fonciere' &&
          d.infoType === 'FUNCTIONAL'
      );
      // Prolonger l'erreur et l'afficher dans la page d'ATF
      this.$router.push({
        name: 'Avis de taxe foncière',
        params: { item: this.listAvisTF, erreur: errFonct }
      });
    },

    dpaeAction(event, dpae) {
      if (event) {
        event.preventDefault();
      }
      if (this.etablissements.length > 1) {
        this.setPreviousPath('declarations').then(() => {
          const routeData = {
            source: SOURCE_PAGE_DECLARATION_DETAIL,
            nextStep: dpae.cible,
            cible: dpae.cible
          };
          this.setRouteDataAndPush(routeData, "Sélection de l'établissement");
        });

        sendTag(
          dpae.libelle,
          this.activeTab === 'current'
            ? 'declarations_en_cours'
            : 'declarations_historique',
          'urssaf',
          ''
        );
      } else if (this.etablissements.length == 1) {
        let cible = dpae.cible.replace('<siret>', this.etablissements[0].siret);
        cible = cible.replace('<categorie>', this.etablissements[0].categorie);
        sendTag(
          dpae.libelle,
          this.activeTab === 'current'
            ? 'declarations_en_cours'
            : 'declarations_historique',
          'urssaf',
          ''
        );
        window.location.href = cible;
      } else {
        console.log('la liste des établissements est vide');
      }
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    getCopro(decla) {
      if (decla.detailDGFIP) {
        return 'impots';
      } else if (decla.detailACOSS) {
        return 'urssaf';
      } else {
        return 'douane';
      }
    },

    getChapter1() {
      return this.activeTab === 'current'
        ? 'declarations_en_cours'
        : 'declarations_historique';
    },

    async loadCurrentData() {
      if (
        this.codeAffichages['bloc-declarations'] &&
        this.codeAffichages['bloc-remboursements']
      ) {
        await Promise.all([
          this.fetchCurrentList(this.$store.state.compte.siren),
          this.fetchCurrentRemboursement(this.$store.state.compte.siren),
          this.fetchUrlDPAE(this.$store.state.compte.siren),
          this.fetchUrlPRO(this.$store.state.compte.siren)
        ]);
        this.gererErreurDecla('err-back-declarations-courant');
        this.gererErreurRemboursement(
          'err-back-declaration-remboursement-courant'
        );
      } else if (this.codeAffichages['bloc-declarations']) {
        await Promise.all([
          this.fetchCurrentList(this.$store.state.compte.siren),
          this.fetchUrlDPAE(this.$store.state.compte.siren),
          this.fetchUrlPRO(this.$store.state.compte.siren)
        ]);
        this.gererErreurDecla('err-back-declarations-courant');
      } else if (this.codeAffichages['bloc-remboursements']) {
        await Promise.all([
          this.fetchCurrentRemboursement(this.$store.state.compte.siren),
          this.fetchUrlDPAE(this.$store.state.compte.siren),
          this.fetchUrlPRO(this.$store.state.compte.siren)
        ]);
        this.gererErreurRemboursement(
          'err-back-declaration-remboursement-courant'
        );
      } else {
        // peuvent être affichés même si pas d'habiltiations (voir maquette déclaraitons ici http://jira-pcr.dgfip.finances.rie.gouv.fr/browse/PCR-1156)
        await Promise.all([
          this.fetchUrlDPAE(this.$store.state.compte.siren),
          this.fetchUrlPRO(this.$store.state.compte.siren)
        ]);
      }
    },

    async loadCurrentAndHistoryData() {
      if (
        this.codeAffichages['bloc-declarations'] &&
        this.codeAffichages['bloc-remboursements']
      ) {
        await Promise.all([
          this.fetchCurrentList(this.$store.state.compte.siren),
          this.fetchHistoryList(this.$store.state.compte.siren),
          this.fetchCurrentRemboursement(this.$store.state.compte.siren),
          this.fetchHistoryRemboursement(this.$store.state.compte.siren),
          this.fetchUrlDPAE(this.$store.state.compte.siren),
          this.fetchUrlPRO(this.$store.state.compte.siren)
        ]);
        this.gererErreurDecla('err-back-declarations-historique');
        this.gererErreurRemboursement(
          'err-back-declaration-remboursement-historique'
        );
      } else if (this.codeAffichages['bloc-declarations']) {
        await Promise.all([
          this.fetchCurrentList(this.$store.state.compte.siren),
          this.fetchHistoryList(this.$store.state.compte.siren),
          this.fetchUrlDPAE(this.$store.state.compte.siren),
          this.fetchUrlPRO(this.$store.state.compte.siren)
        ]);
        this.gererErreurDecla('err-back-declarations-historique');
      } else if (this.codeAffichages['bloc-remboursements']) {
        await Promise.all([
          this.fetchCurrentRemboursement(this.$store.state.compte.siren),
          this.fetchHistoryRemboursement(this.$store.state.compte.siren),
          this.fetchUrlDPAE(this.$store.state.compte.siren),
          this.fetchUrlPRO(this.$store.state.compte.siren)
        ]);
        this.gererErreurRemboursement(
          'err-back-declaration-remboursement-historique'
        );
      } else {
        // peuvent être affichés même si pas d'habiltiations (voir maquette déclaraitons ici http://jira-pcr.dgfip.finances.rie.gouv.fr/browse/PCR-1156)
        await Promise.all([
          this.fetchUrlDPAE(this.$store.state.compte.siren),
          this.fetchUrlPRO(this.$store.state.compte.siren)
        ]);
      }
    },

    gererErreurDecla(idErr) {
      const errDecla = store.state.erreur.details.filter(
        (d) => d.id && d.id.length && d.id === idErr
      );
      if (errDecla && errDecla.length > 0) {
        this.infoServiceDeclarations = true;
      }
    },

    gererErreurRemboursement(idErr) {
      const errRemb = store.state.erreur.details.filter(
        (d) => d.id && d.id.length && d.id === idErr
      );
      if (errRemb && errRemb.length > 0) {
        this.infoServiceRemboursements = true;
      }
    },

    /* Lorsqu'on revient d'une page de détail, recupère l'élément correspondant à la déclaration (ou remboursement)
     * consulté précédement
     */
    getline(detailsNavigation) {
      if (this.$refs.declarations && this.$refs.declarations.children) {
        if (detailsNavigation.table === 'remboursements') {
          return this.$refs.remboursements.children[detailsNavigation.index];
        } else {
          return this.$refs.declarations.children[detailsNavigation.index * 2];
        }
      }
    },

    getClassRs(item) {
      if (
        item.periodeReference &&
        (item.periodeReference.dateDebut || item.periodeReference.dateFin) &&
        !item.dateLimiteDepot &&
        !item.dateDernieDepot &&
        this.activeTab != 'history'
      ) {
        return 'td-table-1-2td-periode-ref';
      } else if (
        (!item.periodeReference ||
          (!item.periodeReference.dateDebut &&
            !item.periodeReference.dateFin)) &&
        item.dateLimiteDepot &&
        !item.dateDernieDepot &&
        this.activeTab != 'history'
      ) {
        return 'td-table-1-2td-date-limite-dpt';
      } else if (
        (!item.periodeReference ||
          (!item.periodeReference.dateDebut &&
            !item.periodeReference.dateFin)) &&
        !item.dateLimiteDepot &&
        item.dateDernieDepot &&
        this.activeTab === 'history'
      ) {
        return 'td-table-1-2td-date-dernier-dpt';
      } else if (
        item.periodeReference &&
        (item.periodeReference.dateDebut || item.periodeReference.dateFin) &&
        item.dateLimiteDepot &&
        !item.dateDernieDepot &&
        this.activeTab != 'history'
      ) {
        return 'td-table-1-3td-periode-ref-dt-lmt-dpt';
      } else if (
        item.periodeReference &&
        (item.periodeReference.dateDebut || item.periodeReference.dateFin) &&
        !item.dateLimiteDepot &&
        item.dateDernieDepot &&
        this.activeTab === 'history'
      ) {
        return 'td-table-1-3td-periode-ref-dt-dernier-dpt';
      } else if (
        (!item.periodeReference ||
          (!item.periodeReference.dateDebut &&
            !item.periodeReference.dateFin)) &&
        item.dateLimiteDepot &&
        item.dateDernieDepot &&
        this.activeTab === 'history'
      ) {
        return 'td-table-1-3td-dt-lmt-dpt-dt-dernier-dpt';
      } else if (
        item.periodeReference &&
        (item.periodeReference.dateDebut || item.periodeReference.dateFin) &&
        item.dateLimiteDepot &&
        item.dateDernieDepot &&
        this.activeTab === 'history'
      ) {
        return 'td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt';
      }
    },

    onFilterForm(values) {
      this.filterValues = { ...values };
      this.resetPaginations();
    },

    onFilterClearForm() {
      this.clearFilters();
    },

    onDeclarationsPaginate(payload) {
      this.paginatedDeclarations = payload;
    },

    onRemboursementsPaginate(payload) {
      this.paginatedRemboursements = payload;
    },

    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData)
        .then(() => {
          this.$router.push({
            name: routeName
          });
        })
        .catch((error) => console.error(error));
    }
  },

  async mounted() {
    if (
      this.chargementCodeAffichages &&
      this.codeAffichages['bloc-declarations-ti']
    ) {
      const lien =
        "<a class='link-header-alert' href='" +
        this.dgfipUrl() +
        "'" +
        " rel='noopener' target='_blank'>impôts.gouv.fr</a>";
      const text =
        "<p class='fr-notice__title'>La déclaration de revenus (déclaration n°2042) des travailleurs indépendants n'est pas disponible sur portailpro.gouv. Plus d'informations sur " +
        lien +
        '</p>';

      this.emitter.emit(HEADER_ALERT, { text: text, visible: true });
    }

    /* Si on est revient d'une page voir le détail
       this.$route.params.index sera défini avec l'index
       de la déclaration qu'on a consulté
       => Dans ce cas on ne recharge pas les données car elles ont déjà été chargées
    */
    const detailsNavigation = JSON.parse(
      localStorage.getItem(DETAILS_NAVIGATION)
    );
    // On vérifie que les code d'affichages sont bien chargés :
    if (!this.chargementCodeAffichages) {
      await this.fetchCodeAffichages(this.$store.state.compte.siren);
      this.fetchIndicateurs(this.$store.state.compte.siren);
    }
    // L'utilisateur revient d'une page de détails via le lien "retour au..." ou le fil d'ariane
    if (
      !detailsNavigation ||
      !(this.getRouteData && this.getRouteData.retourDetail)
    ) {
      // on réalise les appels back en fonction des codes affichages (habilitations) de l'utilisateurs
      await this.loadCurrentData();
    } else {
      this.activeTab = detailsNavigation.tab;
      this.$nextTick()
        .then(() => {
          let decLine;
          this.historyListCalled = detailsNavigation.history;
          decLine = this.getline(detailsNavigation);
          // Si decLine n'est pas défini c'est qu'à un moment donnée l'utilisateur à cliqué sur la flêche de retour en arrière du  navigateur
          // et du coup les données du store on été supprimées il faut dans ce cas là recharger les données avant de recupérer la decLine
          if (!decLine) {
            if (this.historyListCalled) {
              this.loadCurrentAndHistoryData();
            } else {
              this.loadCurrentData();
            }
            decLine = this.getline(detailsNavigation);
          }
        })
        .then(() => {
          const decLine = this.getline(detailsNavigation);
          decLine.classList.toggle('selected-line');
          decLine.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        });
      // On supprime les détails de navigation une fois exploités
      localStorage.removeItem(DETAILS_NAVIGATION);
    }
    this.clearFilters();
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.boundOnFilterForm = this.onFilterForm.bind(this);
    this.boundOnFilterClearForm = this.onFilterClearForm.bind(this);
    this.boundOnDeclarationsPaginate = this.onDeclarationsPaginate.bind(this);
    this.boundOnRemboursementsPaginate = this.onRemboursementsPaginate.bind(
      this
    );
    this.emitter.on(FILTER_FORM, this.boundOnFilterForm);
    this.emitter.on(FILTER_CLEAR_FORM, this.boundOnFilterClearForm);
    this.emitter.on(
      `${PAGINATION_PAGINATE}.${this.dataSourcePaginationName}`,
      this.boundOnDeclarationsPaginate
    );
    this.emitter.on(
      `${PAGINATION_PAGINATE}.${this.remboursementsPaginationName}`,
      this.boundOnRemboursementsPaginate
    );
  },

  unmounted() {
    this.emitter.emit(HEADER_ALERT, { text: '', visible: false });
    window.removeEventListener('resize', this.handleResize);
    this.emitter.off(FILTER_FORM, this.boundOnFilterForm);
    this.emitter.off(FILTER_CLEAR_FORM, this.boundOnFilterClearForm);
    this.emitter.off(
      `${PAGINATION_PAGINATE}.${this.dataSourcePaginationName}`,
      this.boundOnDeclarationsPaginate
    );
    this.emitter.off(
      `${PAGINATION_PAGINATE}.${this.remboursementsPaginationName}`,
      this.boundOnRemboursementsPaginate
    );
  }
};
</script>

<style lang="scss" scoped>
a:focus {
  border-radius: 0;
}

.a-info {
  box-shadow: none;
}

.fr-fi-information-line::before {
  vertical-align: middle;
}

.fr-fi-information-line.dpae::before {
  vertical-align: text-top;
}

h1 {
  color: $base-color;
}

.tabs {
  & button {
    background: white;
    border: 0;
    padding: 20px;
    font-size: 14px;
    color: #6a6a6a;
    border-bottom: 1px solid #e7e7e7;
    outline: 0;

    &.active {
      color: $base-color;
      border-bottom: 2px solid $base-color;
    }
  }
}

.tab {
  background: #f8f8f8;
  position: relative;
  z-index: 0;

  .d-flex {
    color: $base-color !important;
  }
}

th {
  font-size: 14px;
  color: #383838;
  font-weight: 400;
  vertical-align: middle;
  white-space: nowrap;

  &.last {
    white-space: unset !important;
    width: 50%;
  }
}

[class^='ri-']:before,
[class*=' ri-']:before {
  position: relative;
  top: 2px;
  left: -3px;
}

.pr-btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  height: 32px;
  padding: 0 18px;
  margin: -2px 12px 0 12px;
}

button:focus {
  outline: 0;
}

tr {
  border-bottom: 1px solid #dddddd;

  &.table-line {
    vertical-align: middle;
    border-left: solid 3px $base-color;
    border-top: solid 1px #ddd;
    background: white;
    height: 72px;

    &.grey {
      background: #f8f8f8;
    }
  }
}

.td-action {
  font-size: 14px;
}

.siret {
  font-style: italic;
  font-weight: 700;
  font-size: 0.785em;
  padding: 0;
  margin: 0;
}

.spacer {
  height: 1rem;
}

td {
  white-space: pre-line;
  vertical-align: middle;
}

.sub-table {
  position: relative;
  top: 8px;
}

.detail {
  line-height: 2.25rem;
}

.white-space-default {
  white-space: unset;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-38 {
  width: 38% !important;
}

.selected-line {
  box-shadow: 0 3px 8px -4px #777979, 0 -3px 8px -4px #777979;
}

thead {
  background-color: #f9f8f6;
}

.m-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.vl {
  border-left: 2px solid #efefef;
  height: 30px;
}

.hide-element-desktop {
  display: none;
}

table.is-os-mobile {
  display: block;
}

table.is-os-mobile {
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  td {
    /* Behave  like a "row" */
    position: relative;
    padding-left: 0.4rem;
    font-size: 14px;
    padding-top: 1rem;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  td label {
    position: relative;
    top: 20%;
    left: -2%;
    width: 100%;
    white-space: nowrap;
  }

  /*
		Label the data  table -  - 1
		*/
  .td-table-1-1td:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 2
		*/
  .td-table-1-2td-periode-ref:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-2td-periode-ref:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 3
		*/
  .td-table-1-2td-date-limite-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-2td-date-limite-dpt:nth-of-type(2):before {
    content: 'Date limite de dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 4
		*/
  .td-table-1-2td-date-dernier-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-2td-date-dernier-dpt:nth-of-type(2):before {
    content: 'Date du dernier dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 5
		*/
  .td-table-1-3td-periode-ref-dt-lmt-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-3td-periode-ref-dt-lmt-dpt:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-3td-periode-ref-dt-lmt-dpt:nth-of-type(3):before {
    content: 'Date limite de dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 6
		*/
  .td-table-1-3td-periode-ref-dt-dernier-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-3td-periode-ref-dt-dernier-dpt:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-3td-periode-ref-dt-dernier-dpt:nth-of-type(3):before {
    content: 'Date du dernier dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 7
		*/
  .td-table-1-3td-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-3td-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(2):before {
    content: 'Date limite de dépôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-3td-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(3):before {
    content: 'Date du dernier dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 8
		*/
  .td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(3):before {
    content: 'Date limite de dépôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(4):before {
    content: 'Date du dernier dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data second table
		*/
  .td-table-2:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  .td-table-2:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }

  .td-table-2:nth-of-type(3):before {
    content: 'Date de réception de la demande';
    color: gray;
    font-size: 12px;
  }

  .td-table-2:nth-of-type(4):before {
    content: 'Statut';
    color: gray;
    font-size: 12px;
  }

  .td-table-2:nth-of-type(5):before {
    content: 'Montant demandé';
    color: gray;
    font-size: 12px;
  }

  .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  tr {
    border-right: 1px solid rgb(221, 221, 221);
    border-left-color: $base-color !important;
    border-left-width: thick;
  }

  .rs-disappear {
    display: none;
  }

  .fr-table--bordered tbody td,
  .fr-table--bordered tbody th {
    border-bottom: 0;
  }
}

@media only screen and (max-width: 760px) {
  .fr-input {
    padding-top: 0 !important;
  }

  .rs-font {
    font-size: 0.875rem;
  }

  .rs-fs {
    font-size: 1.75rem !important;
  }

  .rs-line-h {
    line-height: 2rem;
  }

  .rs-m {
    margin-bottom: 15px;
  }

  .rs-titre-container {
    margin-bottom: 10px;
    height: 4rem;
  }

  .mt-4 {
    margin-top: 0 !important;
  }

  .rs-table-2 {
    height: 20.5rem !important;
  }

  .rs-table-1-20 {
    height: 20rem !important;
  }

  .rs-table-1-16 {
    height: 16rem !important;
  }

  .rs-table-1-12 {
    height: 12.5rem !important;
  }

  .rs-td {
    font-weight: normal !important;
  }

  .rs-p-t-0 {
    padding-top: 0;
  }

  .ri-file-user-line {
    display: none;
  }

  .titre-container {
    height: auto;

    h1 {
      margin-bottom: 0 !important;
    }
  }
}

.hide-element.is-os-mobile {
  display: none;
}

.hide-element-desktop.is-os-mobile {
  display: block;
}

.btn-secondary.dropdown-toggle {
  background-color: transparent;
  color: $base-color;
  border-style: none;
}

@media only screen and (min-width: 760px) {
  .row {
    margin: auto;
  }

  .color-label {
    color: $base-color;
  }
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.rs-pd {
  margin-top: 10px;
  margin-bottom: 10px;
}

.no-box-shadow {
  box-shadow: none;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  top: 4px !important;
}

#dropdownMenuLink:hover {
  color: $base-color;
}

.dropdown {
  display: inline-flex;
  top: -5px;
}

.btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 0 0 2px #2a7ffe;
}

span.dpae-list::before {
  top: -2px;
}

a.dpae-list::before {
  position: relative;
  top: -5px;
}

.border-btm {
  border-radius: 0px;
  padding-bottom: 0 !important;
  padding-left: 3px;
}

.height-auto {
  height: auto !important;
}

.fs-14 {
  font-size: 14px;
}

a.dropdown-item.pointer {
  color: $base-color;
}

.no-data {
  width: 100%;

  p:first-child {
    display: table-cell;
    vertical-align: middle;
    width: 60%;
  }

  img {
    width: 70%;

    @media only screen and (max-width: 760px) {
      width: 100%;
    }
  }

  p:nth-child(2) {
    text-align: right;
  }
}
</style>
